import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import MetaTags from "../components/userside/metaTags";

import { hiw } from "../components/userside/constent";

const ListItem = ({ title, para, para2, icon }) => {
  return (
    <div className=" mb-12">
      <h1 className="font-bold text-[20px] md:text-[24px] text-gradiant">{title}</h1>
      {icon ? (
        <div></div>
      ) : (
        <>
          <p className="mt-1 text-[12px] md:text-base font-bold md:pl-[28px]">{para}</p>
          <p className="text-[12px] md:text-base font-bold  md:pl-[28px]">{para2}</p>
        </>
      )}
    </div>
  );
};

const HowItWork = () => {
  const navigate = useNavigate();
 
  return (
    <>
    <MetaTags
        title={hiw.title}
        description={hiw.description}
        image={`${window.location.origin}/favicon.ico`}
      />
      <div className=" bg-[#0D1926]">
        <div className="container max-w-7xl mx-auto">
          <div className=" min-h-20 py-10">
            <Header showLogo={false} />
            <div>
              <h1 className=" mt-6 text-white text-4xl font-extrabold ]">
                How it
                <span className=" text-icon"> Works</span>
              </h1>
              <p className="mt-4 text-white text-xl">
                Simple steps to get your vehicle where it needs to be.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-7xl mx-auto py-20">
        <ListItem
          title={"1. Get a Quote"}
          para=" Start by getting a free, no-obligation quote. Fill out our online form
        with details about your vehicle and transport needs."
        />
        <ListItem
          title={"2. Book Your Transport"}
          para="Once you receive your quote and are ready to proceed, book your transport by confirming your details and scheduling a pickup date."
        />
        <ListItem
          title={"3. We Pick Up Your Vehicle"}
          para=" We’ll pick up your vehicle at the arranged time and place. Our expert team handles all the loading and logistics."
        
        />
        <ListItem
          title={"4. Track Your Shipment"}
          para="Once your vehicle is on its way, you can track its journey using our 
          real-time tracking system."
        />
        <ListItem
          title={" 5. Delivery and Confirmation"}
          para="Your vehicle is delivered safely to the specified destination Confirm delivery and enjoy your vehicle!"
          
        />
        <div className=" mt-10 flex gap-2 md:gap-10 ">
          <button onClick={() => navigate('/#quote')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Get a quote
          </button>
          <button onClick={() => navigate('/#callback')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Request a callback
          </button>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
