import "./App.css";
import { Routes, Route } from "react-router-dom";
import Claim from "./components/userside/shipmentclaim/claim";
import TrackShipment from "./components/userside/trackShipment/trackShipment";
import Home from "../src/container/home";
import ConfirmOrder from "./components/userside/bookingplan/confirmOrder";
import ShipmentDetail from "./components/userside/trackShipmentdetail/shipmentDetail";
import Instantquote from "./components/userside/getinstantquote/instantquote";
// import ReferenceDetails from "./components/userside/referncedetails/reference";
import Finalinvoice from "./components/userside/getinstantquote/instantquote";
// import UserForm from "./components/userside/userForm";
import Bookingplans from "./components/userside/bookingplan/confirmOrder";
import MultipleBookings from "./components/userside/confirmModal";
// new
import StripeContainer from "./components/payments/stripeContainer";
import Searchbooking from "./components/userside/searchbookingnew/searchbooking";
import HowItWork from "./container/howItWork";
import ContactUs from "./container/contactUs";
import Service from "./container/service";
import Faqs from "./container/faqs";
function App() {
  return (
    <>
    <head>
      
    </head>
      <div className="App h-100">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/addvehicles" element={<Searchbooking />} />
          <Route path="/how-it-work" element={<HowItWork />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/service" element={<Service />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/claimshipment" element={<Claim />} />
          <Route path="/Trackshipment" element={<TrackShipment />} />
          <Route path="/confirm/order" element={<ConfirmOrder />} />
          <Route path="/shipmentdetail/:id" element={<ShipmentDetail />} />
          <Route path="/getquote" element={<Instantquote />} />
          <Route path="/finalinvoice/:id" element={<Finalinvoice />} />
          <Route path="/bookingplans/" element={<Bookingplans />} />
          <Route path="/multiplebookingplans/" element={<MultipleBookings />} />
          <Route path="/addpayment/" element={<StripeContainer />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
