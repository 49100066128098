import React, { useState } from "react";
import Img from "../assets/8.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
const Header = (props) => {
  const location = useLocation();
  const Navigate = useNavigate();
const [showSidebar,setShowSidebar]= useState(false)
  const { showLogo } = props;
  const handleOpenSidebar=()=>{
setShowSidebar(true)
  }

  const handleCloseSidebar=()=>{
    setShowSidebar(false)
      }
  const pages = [
    { name: "Home", path: "/" },
    { name: "How it Works", path: "/how-it-work" },
    { name: "Services", path: "/service" },
    { name: "Contact Us", path: "/contact-us" }
  ];
  const sidePages = [
    { name: "Home", path: "/" },
    { name: "How it Works", path: "/how-it-work" },
    { name: "Services", path: "/service" },
    { name: "Contact Us", path: "/contact-us" },
    { name: "Track Your Order", path: "/Trackshipment" },
    { name: "FAQS", path: "/faqs" },
  ];
  return (
    <>
    <div className=" flex  justify-between py-2 gap-4 items-center">
      <div className="flex gap-4 ">
        {showLogo && (
          <>
            <img className="h-[60px] " src={Img} alt="logo" />
            <h3 className="md:text-6xl text-2xl mt-6 md:mt-0 text-icon font-extrabold ">
              easemover
            </h3>
          </>
        )}
      </div>
      <div className="hidden lg:flex justify-between items-center gap-x-6 xl:gap-x-12">
    {pages.map((page, index) => (
      <h1
        key={index}
        className={`text-xl font-base cursor-pointer ${
          location?.pathname === page.path ? "text-icon" : "text-white"
        }`}
        onClick={() => Navigate(page.path)}
      >
        {page.name}
      </h1>
    ))}
    <h1
        
        className={`text-xl font-base cursor-pointer text-white `}
       
      >
        <a href="https://truck-app-admin.vercel.app/register/" target="_blank" rel="noopener noreferrer">
        Signin as Company
        </a>
      </h1>
  </div>

  <FaBars onClick={handleOpenSidebar} size={30} className="text-[#10E698] block lg:hidden self-end mb-2" />


    </div>
    <div className={`fixed top-0 right-0 z-50 min-h-screen px-8 py-4 ease-in-out duration-500 overflow-y-auto transition-transform ${showSidebar ? "-translate-x-0 " : "translate-x-full"} bg-blackish min-w-full `}>
    <div className="flex gap-1 ">
            <img className="h-[60px] " src={Img} alt="logo" />
            <h3 className="md:text-3xl text-2xl mt-6 md:mt-4 lg:mt-0 text-icon font-extrabold ">
              easemover
            </h3>
          </div>
        <IoCloseSharp size={30} onClick={handleCloseSidebar} className='absolute text-icon top-4 right-4 ' />
        <div className="flex flex-col justify-center items-center w-full gap-8 mt-5">

        
        {sidePages.map((page, index) => (
      <h1
        key={index}
        className={`text-xl font-base cursor-pointer ${
          location?.pathname === page.path ? "text-icon" : "text-white"
        }`}
        onClick={() => Navigate(page.path)}
      >
        {page.name}
      </h1>
    ))}
    <h1
     
        className={`text-xl font-base cursor-pointer text-white`}
       
      >
         <a href="https://truck-app-admin.vercel.app/register/" target="_blank" rel="noopener noreferrer">
        Signin as Company
        </a>
      </h1>
    </div>
    </div>
    
    </>
  );
};

export default Header;

Header.defaultProps = {
  showLogo: true,
};
