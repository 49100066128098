/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */

import { useNavigate } from "react-router-dom";
import tictok from "../../../assets/tik.svg";
import instagram from "../../../assets/ship.svg";
import linked from "../../../assets/lin.svg";
import "./carousel.css";
export default () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className="bestClients-order flex justify-center md:p-20 p-0">
        <div className="container pt-10 pb-10 md:pt-0 md:pb-0">
          <div className="align-mid ">
            <div className="main-head flex justify-center ">
              <h1 className="text-blackish md:text-4xl text-2xl leading-normal font-semibold md:pr-16 pr-0 md:mt-16 mt-4 text-center">
                Working With <br></br>
                The Best Clients and Partners
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* CAROUSEL */}
      <div class="carousel">
        <div class="carousel-inner">
          <input
            class="carousel-open "
            type="radio"
            id="carousel-1"
            name="carousel"
            aria-hidden="true"
            hidden="true"
            checked="checked"
          />
          <div class="carousel-item transition duration-700 ease-in-out">
            <div className="carousel-text bg-blackish text-center text-white md:p-36 p-16 ">
              <span>
                <i class="fa fa-quote-left text-4xl " aria-hidden="true"></i>
              </span>
              <p className="md:text-4xl text-2xl md:mt-16 mt-8 leading-normal">
                "Never Shipped my vehicle before,<br></br> the process was so
                easy and the service was Amazing.”
              </p>
              <p className="text-xl mt-8">Claire Brooks, MI</p>
            </div>
          </div>
          <input
            class="carousel-open"
            type="radio"
            id="carousel-2"
            name="carousel"
            aria-hidden="true"
            hidden="true"
          />
          <div class="carousel-item transition duration-700 ease-in-out">
            <div className="carousel-text bg-blackish text-center text-white md:p-36 p-16 ">
              <span>
                <i class="fa fa-quote-left text-4xl " aria-hidden="true"></i>
              </span>
              <p className="md:text-4xl text-2xl md:mt-16 mt-8 leading-normal">
                “The Process was incredibly easy, Great <br></br> customer
                service and good rates."
              </p>
              <p className="text-xl mt-8">Alexa Young, CA</p>
            </div>
          </div>
          <input
            class="carousel-open"
            type="radio"
            id="carousel-3"
            name="carousel"
            aria-hidden="true"
            hidden="true"
          />
          <div class="carousel-item transition duration-700 ease-in-out">
            <div className="carousel-text bg-blackish text-center text-white md:p-36 p-16 ">
              <span>
                <i class="fa fa-quote-left text-4xl " aria-hidden="true"></i>
              </span>
              <p className="md:text-4xl text-2xl md:mt-16 mt-8leading-normal">
                ""Got a call back instantly, The process <br></br> was easy and
                simple. Definately recommend"”
              </p>
              <p className="text-xl mt-8">Randall Johns, NY</p>
            </div>
          </div>
          <label for="carousel-3" class="carousel-control prev control-1">
            ‹
          </label>
          <label for="carousel-2" class="carousel-control next control-1">
            ›
          </label>
          <label for="carousel-1" class="carousel-control prev control-2">
            ‹
          </label>
          <label for="carousel-3" class="carousel-control next control-2">
            ›
          </label>
          <label for="carousel-2" class="carousel-control prev control-3">
            ‹
          </label>
          <label for="carousel-1" class="carousel-control next control-3">
            ›
          </label>
          <ol class="carousel-indicators">
            <li>
              <label for="carousel-1" class="carousel-bullet">
                •
              </label>
            </li>
            <li>
              <label for="carousel-2" class="carousel-bullet">
                •
              </label>
            </li>
            <li>
              <label for="carousel-3" class="carousel-bullet">
                •
              </label>
            </li>
          </ol>
        </div>
      </div>
      <div className="shippment-claim p-10  grid grid-cols-1 md:grid-cols-3  ">
        <div className=" ">
          <h1 className="hover:text-red font-semibold cursor-pointer" onClick={() => Navigate("/faqs")}>FAQS</h1>

          <a
            href=""
            className="hover:text-red font-semibold"
            onClick={() => Navigate("/claimshipment")}
          >
            Report An Issue With Your Shipment
          </a>
          <a href="https://truck-app-admin.vercel.app/register/" target="_blank" rel="noopener noreferrer">
          <h1 className="text-blackish cursor-pointer  font-medium">Sign in as a company</h1>
          </a>
        </div>
        <div className="flex flex-col items-center justify-end mt-4 lg:mt-0 ">
      

<div className="mt-8 flex gap-4">
          <div>
          <a href="https://www.instagram.com/easemover?utm_source=qr&igsh=MTFrMGdpeGpndmJoMQ%3D%3D" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={instagram} alt="" />
            </a>
          </div>
          <div>
          <a href="https://www.tiktok.com/@easemover?_t=8ljmKdkRiLN&_r=1" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={tictok} alt="" />
            </a>
          </div>
          <div>
          <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={linked} alt="" />
            </a>
          </div>
        </div>

          <div className="text-center mt-2">
            
            &#169; 2023 <strong>EaseMover</strong> All Rights Reserved
          </div>
        </div>
       
      </div>
    </>
  );
};
