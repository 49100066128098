/* eslint-disable import/no-anonymous-default-export */
import {  useState } from "react";
import axios from "axios";
import Modal from "../inquirymodal";
import "../frontpage/animation.css";
import Header from "../../header";
import { useNavigate } from "react-router-dom";
export default ({callback,callbackRef}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState({
    show: false,
    title: "",
  });
  const handleModal = () => {
    setShowModal((st) => ({
      ...st,
      show: true,
      title: " Quote Sent Successfully !",
    }));
  };
  const handleApi = (e) => {
    e.preventDefault();
    setLoader(true);
    if ((firstName, lastName, email, phone, message)) {
      axios
        .post(`${process.env.REACT_APP_API_KEY}/query/add`, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          message: message,
        })
        .then((res) => {
          handleModal();
          setLoader(false);
          sendNotification();
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
        });
    } else {
      setLoader(false);
      alert("Please Fill The Form");
    }
  };
  const notification = firstName + " Filled the Callback Form";
  const sendNotification = () => {
    axios.post(`${process.env.REACT_APP_API_KEY}/driver/postNotifications`, {
      message: notification,
    });
  };
 
  return (
    <>
      <div  className="main-page bg-buildings bg-cover bg-no-repeat pb-32 ">
        <div className="flex justify-center ">
          <div className="container max-w-7xl mx-auto">
            <Header />

            <div className="cols grid grid-cols-1 lg:grid-cols-2 justify-between ">
              <div className="desc flex flex-col justify- w-full ">
                <div className="explain lg:mt-52 mt-16">
                  <h1 className="text-4xl text-icon font-extrabold ">
                    Easemover
                  </h1>
                  <h1 className="text-3xl text-white font-base mt-4">
                    {" "}
                    Shipping Made Easy
                  </h1>
                  <p className="text-[#e9e8e9] text-lg mt-4 font-extralight max-w-md">
                    {" "}
                    We are a fully licensed and insured transport business that
                    takes pride in our quality of customer care. We offer
                    professional transport services at affordable rates. Feel
                    free to contact us so you can experience personally, how
                    comfortable your car shipping experience can be with us.
                  </p>
                </div>
                <div className="btn mt-8 flex justify-center md:justify-start">
                  <button onClick={() => navigate('/#quote')} className="bg-icon px-10 py-2 font-bold text-blackish">
                    <a href="#viewQuote">Get Instant Quote</a>
                  </button>
                </div>
              </div>
              <div className="relative justify-self-center mb-16  lg:absolute z-10 lg:left-[57%] sm:left-0 ">
                <div  ref={callbackRef} id={callback} className="bg-blackish p-4 md:p-8 mx-auto mt-28 mb-16 md:w-[450px] w-[320px]">
                  <h1 className="text-icon font-bold tracking-wider text-3xl text-center">
                    Shipment Inquiry
                  </h1>
                  <p className="text-[#e9e8e9] text-center  font-thin mt-3 text-base">
                    Contact us to get the best Quotes <br></br> Nationwide
                  </p>
                  <form
                 
                    className="w-full max-w-lg mt-10"
                    onSubmit={(e) => {
                      handleApi(e);
                    }}
                  >
                    <div className="w-100 px-3 mb-6">
                      <label
                        className="vtlabel"
                        for="grid-first-name"
                      >
                        First Name*
                      </label>
                      <input
                        className="fpinput"
                        id="grid-first-name"
                        type="text"
                        placeholder="Enter Your First Name"
                        required
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-100  px-3">
                      <label
                        className="vtlabel"
                        for="grid-last-name"
                      >
                        Last Name*
                      </label>
                      <input
                        className="fpinput "
                        id="grid-last-name"
                        type="text"
                        placeholder="Enter Your Last Name"
                        required
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="mt-4">
                      <div className="w-full px-3">
                        <label
                          className="vtlabel"
                          for="grid-email"
                        >
                          Email*
                        </label>
                        <input
  className="fpinput"
  id="grid-email"
  type="email"
  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
  placeholder="Enter Your Email"
  required
  value={email}
  onChange={(e) => {
    setEmail(e.target.value);
  }}
/>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="w-100 px-3 mb-6 md:mb-0">
                        <label
                          className="vtlabel"
                          for="grid-state"
                        >
                          Phone*
                        </label>
                        <input
                          className="fpinput"
                          id="grid-city"
                          type="text" // Use type="text"
                          pattern="[0-9]*" // Set pattern to accept only numbers
                          placeholder="Phone"
                          required
                          value={phone}
                          onChange={(e) => {
                            // Remove non-numeric characters using regular expression
                            const numericValue = e.target.value.replace(/\D/g, '');
                            setPhone(numericValue);
                          }}
                        />
                      </div>
                    </div>
                    <div className=" mt-4">
                      <div className="w-full px-3">
                        <label
                          for="message"
                          className="vtlabel"
                        >
                          Message*
                        </label>
                        <textarea
                          id="message"
                          rows="4"
                          className="fpinput"
                          placeholder="Enter Your Message"
                          required
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-4 px-3">
                      {loader ? (
                        <div className="text-center w-full px-3 text-blackish p-3 font-semibold text-lg bg-icon">
                          <span className="text-white p-2 text-lg">
                            Sending...
                          </span>
                          <svg
                            class="animate-spin inline text-white h-[15px] w-[15px] border-4  ..."
                            viewBox="0 0 0 0"
                          ></svg>
                        </div>
                      ) : (
                        <div className="w-full px-3 text-center text-blackish p-3 font-bold text-sm md:text-lg bg-icon ">
                          <button
                            className=""
                            disabled={loader}
                            // onClick={(e) => {
                            //   handleApi(e);
                            // }}
                            type="submit"
                          >
                            Recieve a call back in 2 Minutes!{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="white-border mt-16 mb-28 hidden lg:block justify-self-end">
                <div className="border-slide  h-full h-[45rem] w-[25rem] ml- border-white border-8 jjj"></div>
              </div>
            </div>
          </div>
        </div>
        {showModal?.show && (
          <Modal setShowModal={setShowModal} showModal={showModal} />
        )}
      </div>
    </>
  );
};
