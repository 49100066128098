/* eslint-disable import/no-anonymous-default-export */
import cars from '../../../assets/cars.mp4'
export default () => {
  return (
    <>
      <div className="shipping-done pb-10 px-4 md:px-10">
        <div className="main-heading text-blackish text-center md:mt-16 mt-8 ">
          <h1 className=" md:text-6xl text-3xl font-bold">Get Shipping Done</h1>
          <h4 className="md:text-4xl text-2xl md:mt-10 mt-5 font-semibold">In 3 Easy Steps</h4>
        </div>
        <div className=" mt-16">
          <div className="container flex-col lg:flex-row cards flex lg:justify-center mx-auto">
            <div className="square w-full min-w-[230px]">
              <div className="test mx-auto flex justify-center front bg-icon h-105 w-110">
                <div className="back-texture background bg-blackish  text-center mt-[10px] -mr-[22px] h-105 w-110">
                  <i class="fa-solid fa-pen-to-square text-icon text-6xl font-extralight mt-6"></i>
                </div>
              </div>
              <div className="info mt-6 text-center">
                <h1 className="md:mt-16 mt-2 font-bold">Provide</h1>
                <p className="md:mt-6 mt-2 font-semibold">All details required </p>
              </div>
            </div>
            <div className="horizental-line ml-6 mt-16 hidden lg:block">
              <hr className="bg-icon h-4 w-[100px] xl:w-[180px] "></hr>
            </div>
            <div className="square mt-16 w-full min-w-[230px] lg:mt-0">
              <div className="test mx-auto flex justify-center front bg-icon h-105 w-110">
                <div className="back-texture background bg-blackish  text-center mt-[10px] -mr-[22px] h-105 w-110">
                  <i className="fa-solid fa-clipboard text-icon text-6xl font-extralight mt-6"></i>
                </div>
              </div>
              <div className="info mt-6 text-center">
                <h1 className="md:mt-16 mt-2  font-bold">Select time & date </h1>
                <p className="md:mt-6 mt-2 font-semibold">
                  Select a transport type and schedule the best that suits your
                  shipping needs.
                </p>
              </div>
            </div>
            <div className="horizental-line ml-2 mt-16 hidden lg:block">
              <hr className="bg-icon h-4 w-[100px] xl:w-[180px]"></hr>
            </div>
            <div className="square mt-16 w-full min-w-[230px] lg:mt-0">
              <div className="test mx-auto flex justify-center front bg-icon h-105 w-110">
                <div className="back-texture background bg-blackish  text-center mt-[10px] -mr-[22px] h-105 w-110">
                  <i className="fa-solid fa-map-location-dot text-icon  text-6xl mt-6"></i>
                </div>
               </div>
              <div className="info mt-6 text-center">
                <h1 className="md:mt-16 mt-2  font-bold">Ship & Recieve</h1>
                <p className="md:mt-6 mt-2 font-semibold">Get real time updates on your cargo</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
          <div className="btn mt-16 w-full flex justify-center text-center items-center  pb-4 container">
          <a href ="#viewQuote" className="w-full px-8 border-4 border-icon text-blackish font-semibold hover:bg-blackish hover:text-white  "> 
              Get Started
           </a>  
          </div>
          </div>
        </div>

        
      </div>
      <video className='hidden md:block aspect-video mt-6' autoPlay loop>
  <source src={cars} type="video/mp4"/>
  Your browser does not support the video tag.
</video>

    </>
  );
};
