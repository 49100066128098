/* eslint-disable import/no-anonymous-default-export */
import Page1 from "../components/userside/frontpagenew/frontpagenew";
import Page2 from "../components/userside/vehicleTypeNew/vehicleTypeNew";
import Page3 from "../components/userside/getShippingDone/getShippingDone";
import Page4 from "../components/userside/trackOrder/trackOrder";
import Page5 from "../components/userside/bestClients/bestClients";
import MetaTags from "../components/userside/metaTags";
import { metas } from "../components/userside/constent";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

export default () => {
  const location = useLocation();
    const quoteRef = useRef(null);
    const callbackRef = useRef(null);

    useEffect(() => {
        const hash = location.hash.substr(1);
        if (hash === 'quote' && quoteRef.current) {
            setTimeout(() => {
                quoteRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        } else if (hash === 'callback' && callbackRef.current) {
            setTimeout(() => {
                callbackRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    }, [location]);
  return (
    <>
      <MetaTags
        title={metas.title}
        description={metas.description}
        image={`${window.location.origin}/favicon.ico`}
      />
      <Page1 callbackRef={callbackRef} callback={"callback"} />
      <Page2 quoteRef={quoteRef} quote={"quote"}/>
      <Page3 />
      <Page4 />
      <Page5 />
    </>
  );
};
