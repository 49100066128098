import axios from "axios";

class DashboardService {
  getContactList(userId) {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/admin/user/chat/${userId}/contact-list`,
      config
    );
  }

  addNewContact(data, id) {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/admin/user/chat/${id}/contact-list`,
      data,
      config
    );
  }

  getChat(params) {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    };

    return axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/admin/messages`,
      config
    );
  }

  addNewMessage(data) {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/admin/messages`,
      data,
      config
    );
  }

  getPlaces(value) {
    let params = {
      query: value,
      key: "AmSRC9UHoeIZradJLYgA1M4g5m_gsoGGgr9GG7CajroExFrJUeiJ767x50blw07v",
    };
    const config = {
      headers: {},
      params,
    };

    return axios.get(`http://dev.virtualearth.net/REST/v1/Autosuggest`, config);
  }
}

export default new DashboardService();
