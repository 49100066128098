/* eslint-disable import/no-anonymous-default-export */
import { useState } from 'react'
import axios from 'axios';
import Modal from '../.././userside/inquirymodal';
import {  useNavigate } from 'react-router-dom';
export default()=>{
    const Navigate=useNavigate();
    const[firstName,setFirstName]=useState("");
    const[lastName,setLastName]=useState("");
    const[email,setEmail]=useState("");
    const[trackingId,setTrackingId]=useState("");
    const[issue,setIssue]=useState("");
    const[loader,setLoader]=useState(false);
    const[showModal,setShowModal]=useState({
        show:false,
        title:'',
    })
    const handleModal=()=>{
        setShowModal((st)=>({
            ...st,
            show:true,
            title:"Shipment Claim Successfully Sent !"
        }))
    }
    const handleApi=(e)=>{
        e.preventDefault();
        setLoader(true);
        if(firstName,lastName,email,trackingId,issue){
        axios.post(`${process.env.REACT_APP_API_KEY}/claim/add`,{
            firstName:firstName,
            lastName:lastName,
            email:email,
            trackingId:trackingId,
            issue:issue,
        })     
        .then((res)=>{
            setLoader(false);
            sendNotification();
            handleModal();
            setFirstName("");
            setLastName("");
            setEmail("");
            setTrackingId("");
            setIssue("");
        })
    }else{
        setLoader(false);
        alert("Please Fill The Form")
    }
}
const notification=firstName + " reported an issue with shipment"
const sendNotification=()=>{
    axios.post(`${process.env.REACT_APP_API_KEY}/driver/postNotifications`,{
        message:notification,
    })
}
    return(
        <>
        <div className="background-image bg-buildings bg-cover bg-no-repeat h-[130%]">
            <div className="shipment-claim flex justify-center p-16">
                <div className="container flex justify-center ">
                    <div className="form bg-white p-20">
                    <button className=' border-white bg-black float-right p-2 text-white text-sm  hover:rounded' onClick={() => Navigate('/')}> Go Back</button>
                    <h1 className="text-blackish font-semibold text-2xl"> Report An Issue With <br></br> Your Shipment</h1>
                    <form className="w-full max-w-lg mt-10">
                   
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block  tracking-wide text-blackish text-xs font-bold mb-2">
                                                First Name*
                                            </label>
                                            <input className="appearance-none block w-full mt-3 bg-gray-200 text-blackish border-b-blackish border border-white py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                            value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block  tracking-wide text-blackish text-xs font-bold mb-2" >
                                                Last Name*
                                            </label>
                                            <input className="appearance-none mt-3 block w-full bg-gray-200 text-blackish border-b-blackish border border-white py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text"
                                            value={lastName} onChange={(e)=>setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="-mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block  tracking-wide text-blackish text-xs font-bold mb-2" >
                                                Email*
                                            </label>
                                            <input className="appearance-none block mt-3 w-100 bg-gray-200 text-blackish border-b-blackish border border-white py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email"
                                            value={email} onChange={(e)=>setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="-mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block  tracking-wide text-blackish text-xs font-bold mb-2" >
                                                Tracking Number*
                                            </label>
                                            <input className="appearance-none block mt-3 w-100 bg-gray-200 text-blackish border-b-blackish border border-white py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="number" 
                                            value={trackingId} onChange={(e)=>{setTrackingId(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                    <div className="-mx-3 mb-6 ">
                                        <div className="w-full px-3">
                                            <label className="block  tracking-wide mt-3 text-blackish text-xs font-bold ">Issue*</label>
                                            <textarea id="message" rows="4" className="bappearance-none block mt-3 w-100 bg-gray-200 text-gray-700  border-b-blackish border border-white  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            value={issue} onChange={(e)=>{setIssue(e.target.value)}}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="-mx-3 mb-6">
                                    {loader ? <div className='text-center text-white rounded-xl py-2 px-6 w-195 bg-blackish '>
                          <span className='text-white p-1'>Sending...</span>
                          <svg class="animate-spin inline text-white h-20 w-20 border-4  ..." viewBox="0 0 0 0"></svg></div>:
                                        <div className="w-full px-3 ">
                                            <button className=" text-white py-2 px-6 mt-8   bg-blackish rounded-xl"
                                            onClick={(e)=>{handleApi(e)}}
                                            >
                                                Submit</button>
                                        </div>}
                                    </div>
                                </form>
                    </div>
                </div>
            </div>
            
            {showModal?.show && (
                <Modal showModal={showModal} setShowModal={setShowModal} />
            )
        }
        </div>
       
        </>
    )
}