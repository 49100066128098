/* eslint-disable import/no-anonymous-default-export */
import "react-datepicker/dist/react-datepicker.css";
import Img1 from "../../../assets/close.jpg";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import BingMap from "../bingMap";
import dashboard from "../../../services/dashboard";
import "../vehicleType/vehicle.css";
import { useCallback } from "react";
export default ({ quote, quoteRef }) => {
  const startDate = moment().format("DD-MM-YYYY");
  const endDate = moment().add(4, "day").format("YYYY-MM-DD");
  const minDate = new Date();
  const nextThreeDay = new Date(minDate);
  nextThreeDay.setDate(minDate.getDate() + 4);
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);
  const [showFrom, setShowFrom] = useState(false);
  const [state, setState] = useState({
    value: "",
  });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [pickupDate, dropoffDate] = dateRange;
  const [info, setInfo] = useState({});
  const [years, setYears] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredYears, setFilteredYears] = useState([]);
  const [filteredMakes, setFilteredMakes] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [repeat, setRepeat] = useState([
    {
      id: 1,
    },
  ]);

  const [dropdownVisibility, setDropdownVisibility] = useState({
    years: [],
    makes: [],
    models: []
  });


  useEffect(() => {
    fetch('https://easemover-backend.vercel.app/shipment/makeyear')
      .then(response => response.json())
      .then(data => {
        setAllData(data.data); // Update to match the new data structure
        const uniqueYears = data.data.map(item => item.year);
        setYears(uniqueYears);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const onInputChanges = useCallback((e, index) => {
    const { name, value } = e.target;
    const newFormValues = [...repeat];
    newFormValues[index][name] = value;
    setRepeat(newFormValues);

    if (name === 'year') {
      setFilteredYears(years.filter(year => year.toString().includes(value)));

      const selectedYearData = allData.find(item => item.year.toString() === value);
      if (selectedYearData) {
        const filteredMakes = selectedYearData.makes.map(make => make.make);
        setFilteredMakes(filteredMakes);
        setMakes(filteredMakes);
      } else {
        setFilteredMakes([]);
        setMakes([]);
      }
      setFilteredModels([]);
      setModels([]);
      newFormValues[index].make = '';
      newFormValues[index].model = '';
    }

    if (name === 'make') {
      setFilteredMakes(makes.filter(make => make.toLowerCase().includes(value.toLowerCase())));

      const selectedYearData = allData.find(item => item.year === parseInt(repeat[index].year));
      if (selectedYearData) {
        const selectedMakeData = selectedYearData.makes.find(make => make.make.toLowerCase().includes(value.toLowerCase()));
        if (selectedMakeData) {
          setFilteredModels(selectedMakeData.models);
        } else {
          setFilteredModels([]);
        }
      }
    }

    if (name === 'model') {
      setFilteredModels(models.filter(model => model.toLowerCase().includes(value.toLowerCase())));
    }
  }, [repeat, allData, years, makes, models]);

  const onSelectYear = useCallback((year, index) => {
    const newFormValues = [...repeat];
    newFormValues[index].year = year;
    newFormValues[index].make = '';
    newFormValues[index].model = '';
    setRepeat(newFormValues);
    setDropdownVisibility(prev => ({
      ...prev,
      years: { ...prev.years, [index]: false },
      makes: { ...prev.makes, [index]: false },
      models: { ...prev.models, [index]: false }
    }));

    const selectedYearData = allData.find(item => item.year === year);
    if (selectedYearData) {
      const filteredMakes = selectedYearData.makes.map(make => make.make);
      setMakes(filteredMakes);
      setFilteredMakes(filteredMakes);
    } else {
      setMakes([]);
      setFilteredMakes([]);
    }
    setModels([]);
    setFilteredModels([]);
  }, [repeat, allData]);

  const onSelectMake = useCallback((make, index) => {
    const newFormValues = [...repeat];
    newFormValues[index].make = make;
    newFormValues[index].model = '';
    setRepeat(newFormValues);
    setDropdownVisibility(prev => ({
      ...prev,
      makes: { ...prev.makes, [index]: false },
      models: { ...prev.models, [index]: false }
    }));

    const selectedYearData = allData.find(item => item.year === parseInt(repeat[index].year));
    if (selectedYearData) {
      const selectedMakeData = selectedYearData.makes.find(makeItem => makeItem.make === make);
      if (selectedMakeData) {
        const filteredModels = selectedMakeData.models;
        setModels(filteredModels);
        setFilteredModels(filteredModels);
      } else {
        setModels([]);
        setFilteredModels([]);
      }
    }
  }, [repeat, allData]);

  const onSelectModel = useCallback((model, index) => {
    const newFormValues = [...repeat];
    newFormValues[index].model = model;
    setRepeat(newFormValues);
    setDropdownVisibility(prev => ({
      ...prev,
      models: { ...prev.models, [index]: false }
    }));
  }, [repeat]);





  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInfo((st) => ({
      ...st,
      [name]: value,
    }));
  };
  const [checked, setChecked] = useState({
    checkbox1: false,
    checkbox2: false,
  });
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setChecked({
      checkbox1: value === "I am Flexible",
      checkbox2: value === "Ship ASAP",
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !repeat?.[0]?.vehicleType ||
      !repeat?.[0]?.make ||
      !repeat?.[0]?.model ||
      !repeat?.[0]?.year ||
      !state?.fromValue ||
      !state?.toValue
    ) {
      alert("Please fill Form");
    } else {
      Navigate("/addvehicles", { state: payload });
      console.log(payload);
    }
  };
  let payload = {
    ...info,
    repeat,
    pickupDate: `${moment(pickupDate).format("YYYY-MM-DD")} to ${moment(
      dropoffDate
    ).format("YYYY-MM-DD")}`,
  };
  payload.finalDistance = state?.finalDistance;
  payload.origin = state?.addressTo;
  payload.destination = state?.addressFrom;
  const handleResultSelect = (val, origin, index) => {
    if (origin === "to") {
      setState((st) => ({
        ...st,
        toValue: val,
        addressTo: val,
      }));
    } else {
      setState((st) => ({
        ...st,
        fromValue: val,
        addressFrom: val,
      }));
      axios
        .get(
          `http://dev.virtualearth.net/REST/V1/Routes/Driving?wp.0=${state?.addressTo}&wp.1=${val}&avoid=minimizeTolls&key=AmSRC9UHoeIZradJLYgA1M4g5m_gsoGGgr9GG7CajroExFrJUeiJ767x50blw07v`
        )
        .then((res) => {
          setState((st) => ({
            ...st,
            finalDistance:
              res?.data?.resourceSets?.[0]?.resources?.[0]?.travelDistance,
          }));
        });
    }
  };
  const handleSearchChange = async (value, origin, index) => {
    if (origin === "to") {
      setState((st) => ({
        ...st,
        toValue: value,
      }));
    } else {
      setState((st) => ({
        ...st,
        fromValue: value,
      }));
    }
    dashboard
      .getPlaces(value)
      .then((res) => {
        setState((st) => ({
          ...st,
          data: res?.data?.resourceSets?.[0]?.resources?.[0]?.value,
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  const removeLastItem = () => {
    const newArray = repeat.slice(0, -1);
    setRepeat(newArray);
  };


  return (
    <>
      <div
        ref={quoteRef}
        id={quote}
        className="type flex justify-center bg-blackish pb-16"
      >
        <div className="container max-w-[1037px]">
          <div className="ss">
            {repeat.map((_, index) => (
              <div key={index}>
                {index === 0 && (
                  <div className="head mt-2 text-center ">
                    <h1 className="text-white text-2xl font-semibold mt-8">
                      Quote Your Transport
                    </h1>
                  </div>
                )}
                {index !== 0 && (
                  <div className="flex justify-between border-b-icon border-b-2 py-4 mt-8">
                    <p className="text-icon text-xl">{index + 1}</p>

                    <div>
                      <img
                        className="h-[20px] cursor-pointer"
                        src={Img1}
                        alt="close"
                        onClick={() => removeLastItem()}
                      />
                    </div>
                  </div>
                )}
                <div class="grid grid-cols-6 gap-4 mt-16">
                  {/* from */}
                  {index === 0 && (
                    <div className="w-full col-span-3 md:col-span-2 relative">
                      <div className="icons w-full">
                        {hover ? (
                          <h3 className="text-icon text-[11px] absolute mb-20 -top-14 ">
                            Note: Final quote may vary If <br></br> Full address
                            is not provided for the <br></br> pickup and dropoff
                            locations.
                          </h3>
                        ) : (
                          ""
                        )}
                        <label
                          className="vtlabel"
                          for="from"
                        >
                          From*
                        </label>
                        {!show ? (
                          <div
                            className="vtinput"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => {
                              setShow(true);
                              setHover(true);
                            }}
                          >
                            <span className="inline-flex cursor-pointer justify-between items-center min-w-full  text-[#e9e8e9] text-xs md:text-base ">
                              City Zip Code{" "}
                              <i className="fa-solid fa-location-dot text-icon md:text-white ml-5 hover:text-lg"></i>
                            </span>
                          </div>
                        ) : (
                          <div
                            className="map text-blackish text-normal w-full font-semibold col-span-3 md:col-span-2"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                          >
                            {" "}
                            <BingMap
                              className={"bg-blackish z-10"}
                              setState={setState}
                              state={state}
                              origin="to"
                              value={state?.toValue}
                              handleChange={(val) => {
                                handleSearchChange(val.target.value, "to");
                              }}
                              handleSelect={(val) =>
                                handleResultSelect(val, "to")
                              }
                              onChange={() => setShow(true)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* to */}
                  {index === 0 && (
                    <div className="col-span-3 md:col-span-2">
                      {" "}
                      <div className="icons w-[100%] ">
                        <label className="vtlabel">
                          To*
                        </label>
                        {!showFrom ? (
                          <div
                            className="vtinput"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => setShowFrom(true)}
                          >
                            <span className="inline-flex cursor-pointer justify-between items-center min-w-full  text-[#e9e8e9] text-xs md:text-base ">
                              City Zip Code{" "}
                              <i className="fa-solid fa-location-dot ml-5 text-icon md:text-white hover:text-lg"></i>
                            </span>
                          </div>
                        ) : (
                          <div
                            className="map text-blackish text-normal font-semibold"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                          >
                            <BingMap
                              setState={setState}
                              state={state}
                              origin="from"
                              handleChange={(val) => {
                                handleSearchChange(val.target.value, "from");
                              }}
                              value={state?.fromValue}
                              handleSelect={(val) =>
                                handleResultSelect(val, "from")
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* select vehicle type */}
                  <div className="col-span-3 md:col-span-2 ">
                    <label
                      className="vtlabel"
                      for="vehicle Type"
                    >
                      Select Vehicle Type*
                    </label>
                    <select
                      className="vtselect"
                      name="vehicleType"
                      id="cars"
                      onChange={(e) => {
                        onInputChanges(e, index);
                      }}
                    >
                      <option data-hidden="true" value="">
                        Select
                      </option>
                      <option value={"Sedan"}>Sedan</option>
                      <option value={"SUV"}>SUV</option>
                      <option value={"Minivan"}>Minivan</option>
                      <option value={"Pickup Truck"}>Pickup Truck</option>
                      <option value={"Convertible"}>Convertible</option>
                      <option value={"Motorcycle"}>Motorcycle</option>
                      <option value={"RV"}>RV</option>
                      <option value={"Cargo"}>Heavy Equipment</option>
                      <option value={"Boat"}>Boat</option>
                      <option value={"Other"}>Other</option>
                    </select>
                  </div>

                  {/* Vin Number */}

                  {/* <div className="col-span-3">
                    <label
                      className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                      for="vin number"
                    >
                      Vin Number
                    </label>
                    <input
                      className="appearance-none block w-[100%]  mt-4  bg-blackish text-white border-2 md:border  border-icon md:border-[#e9e8e9]  py-3 px-4 mb-3 leading-tight focus:outline-none text-xs md:text-base"
                      id="vin number"
                      type="number"
                      placeholder="Enter Vin Number"
                      required
                      name="vin"
                      pattern="[0-9]*"
                      value={info?.vin}
                      onChange={(e) => {
                        onInputChanges(e, index);
                      }}
                    />
                  </div> */}

                  {/* Year */}
                  <div className="col-span-2 md:col-span-1 relative">
                    <label className="vtlabel" htmlFor={`year-${index}`}>Year*</label>
                    <input
                      type="number"
                      className="vtinput"
                      id={`year-${index}`}
                      name="year"
                      placeholder="Select Year"
                      value={repeat[index].year || ''}
                      onChange={(e) => onInputChanges(e, index)}
                      onFocus={() => {
                        setDropdownVisibility(prev => ({
                          ...prev,
                          years: { ...prev.years, [index]: true }
                        }));
                        setFilteredYears(years);
                      }}
                      onBlur={() => setTimeout(() => setDropdownVisibility(prev => ({
                        ...prev,
                        years: { ...prev.years, [index]: false }
                      })), 200)}
                      required
                    />
                    {dropdownVisibility.years[index] && (
                      <ul className="vtul">
                        {filteredYears.map(year => (
                          <li key={year} onClick={() => onSelectYear(year, index)} className="vtli">{year}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* Make */}
                  <div className="col-span-2 md:col-span-1 relative">
                    <label className="vtlabel" htmlFor={`make-${index}`}>Make*</label>
                    <input
                      type="text"
                      className="vtinput"
                      id={`make-${index}`}
                      name="make"
                      placeholder="Select Make"
                      value={repeat[index].make || ''}
                      onChange={(e) => onInputChanges(e, index)}
                      onFocus={() => {
                        setDropdownVisibility(prev => ({
                          ...prev,
                          makes: { ...prev.makes, [index]: true }
                        }));
                        setFilteredMakes(makes);
                      }}
                      onBlur={() => setTimeout(() => setDropdownVisibility(prev => ({
                        ...prev,
                        makes: { ...prev.makes, [index]: false }
                      })), 200)}
                      required
                    />
                    {dropdownVisibility.makes[index] && (
                      <ul className="vtul">
                        {filteredMakes.map(make => (
                          <li key={make} onClick={() => onSelectMake(make, index)} className="vtli">{make}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* Model */}
                  <div className="col-span-2 md:col-span-1 relative">
                    <label className="vtlabel" htmlFor={`model-${index}`}>Model*</label>
                    <input
                      type="text"
                      className="vtinput"
                      id={`model-${index}`}
                      name="model"
                      placeholder="Select Model"
                      value={repeat[index].model || ''}
                      onChange={(e) => onInputChanges(e, index)}
                      onFocus={() => {
                        setDropdownVisibility(prev => ({
                          ...prev,
                          models: { ...prev.models, [index]: true }
                        }));
                        setFilteredModels(models);
                      }}
                      onBlur={() => setTimeout(() => setDropdownVisibility(prev => ({
                        ...prev,
                        models: { ...prev.models, [index]: false }
                      })), 200)}
                      required
                    />
                    {dropdownVisibility.models[index] && (
                      <ul className="vtul">
                        {filteredModels.map(model => (
                          <li key={model} onClick={() => onSelectModel(model, index)} className="vtli">{model}</li>
                        ))}
                      </ul>
                    )}
                  </div>

                  {/* Operable */}
                  <div className="col-span-3">
                    <label
                      className="vtlabel"
                      for="operable"
                    >
                      Operable*
                    </label>
                    <select
                      className="vtselect"
                      name="operable"
                      onChange={(e) => {
                        onInputChanges(e, index);
                      }}
                    >
                      <option data-hidden="true" value="">
                        Select
                      </option>
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                  </div>

                  {/* transport Type */}
                  {index === 0 && (
                    <div className="w-[100%] col-span-3">
                      <label
                        className="vtlabel"
                        for="Transport Type"
                      >
                        Transport Type*
                      </label>
                      <select
                        className="vtselect"
                        name="transportType"
                        id="cars"
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      >
                        <option data-hidden="true" value="">
                          Select
                        </option>
                        <option value={"open"}>Open</option>
                        <option value={"enclosed"}>Enclosed</option>
                        <option value={"drive away"}>Drive Away</option>
                      </select>
                    </div>
                  )}
                  {/* Buyer name */}
                  {/* <div className="col-span-3">
                    <label
                      className="block mr-3 uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                      for="buyer-name"
                    >
                      Buyer Name
                    </label>
                    <input
                      className="appearance-none  mr-5 block text-xs md:text-base w-[100%] mt-4 bg-gray-200 text-white bg-blackish border-2 md:border border-icon md:border-[#e9e8e9]  py-3 px-4 mb-3 leading-tight focus:outline-none"
                      id="buyer-name"
                      type="text"
                      placeholder="Enter Buyer Name"
                      required
                      name="buyerName"
                      value={info?.buyerName}
                      onChange={(e) => {
                        onInputChanges(e, index);
                      }}
                    />
                  </div> */}
                  {/* lot number */}
                  {/* <div className="col-span-3">
                    <label
                      className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                      for="lot-number"
                    >
                      Lot Number
                    </label>
                    <input
                      className="appearance-none block w-[100%] text-xs md:text-base mt-4 bg-gray-200 text-white bg-blackish border-2 md:border  border-icon md:border-[#e9e8e9] py-3 px-4 mb-3 leading-tight focus:outline-none "
                      id="lot-number"
                      type="number"
                      placeholder="Enter lot Number"
                      required
                      name="lotNumber"
                      value={info?.lotNumber}
                      onChange={(e) => {
                        onInputChanges(e, index);
                      }}
                    />
                  </div> */}
                </div>

                {index === repeat?.length - 1 && (
                  <div className="btn text-center mt-16 ">
                    <button
                      className="vtaddbtn"
                      onClick={() => {
                        setRepeat([...repeat, { id: "" }]);
                      }}
                    >
                      + Add Another Vehicle
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* ADD ANOTHER VEHICLE ENDS */}
          <div className="flex flex-wrap gap-6 mt-12 justify-center sm:w-[100%]">
            <div className="check-box mt-8">
              <div>
                <input
                  className=" vtcheckbox "
                  type="checkbox"
                  name="priority"
                  value={"I am Flexible"}
                  checked={checked.checkbox1}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                    onInputChange(e);
                  }}
                />
                <label className="text-white ml-2 text-lg ">
                  I'm Flexible{" "}
                </label>
              </div>
            </div>
            <div className="check-box mt-8">
              <div>
                <input
                  className=" vtcheckbox"
                  type="checkbox"
                  name="priority"
                  value={"Ship ASAP"}
                  checked={checked.checkbox2}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                    onInputChange(e);
                  }}
                />

                <label className="text-white ml-2 md:text-lg text-sm ">
                  Ship ASAP
                </label>
                {checked.checkbox2 ? (
                  <div>
                    <p className="text-white text-xs ">
                      {" "}
                      Note: Your Shipment will pickedup <br></br> from{" "}
                      {startDate} to {endDate}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {checked.checkbox2 ? (
            <div className="flex justify-center">
              <button
                className="vtquote mt-16"
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                Get Quote
              </button>
            </div>
          ) : (
            ""
          )}
          {!checked.checkbox2 && checked.checkbox1 ? (
            <div className="flex md:gap-4 sm:gap-2 justify-center md:ml-20 sm:ml-0  flex-wrap">
              <div>
                <div className="vtdatepicker w-full  ">
                  <DatePicker
                    placeholderText={"Select Date Range For Pickup"}
                    selectsRange={true}
                    startDate={pickupDate}
                    endDate={dropoffDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    minDate={nextThreeDay}
                  />

                  <span>
                    <i class="fa-solid fa-calendar-days text-icon md:text-[#c4c3c3]  ml-10"></i>
                  </span>
                </div>
              </div>

              <div className="btn text-center mt-7 ml-8 ">
                <button
                  className="vtquote"
                  onClick={(e) => {
                    onSubmit(e);
                  }}
                >
                  Get Quote
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
