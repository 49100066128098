import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = (props) => {
  const { title, description, image, category } = props;

  // Define keywords based on the category
  let keywords = [];
  switch (category) {
    case "VehicleShipping":
      keywords = [
        "classic car shipping",
        "enclosed vehicle transport",
        "open vehicle transport",
        "safe car shipping North America",
        "insured vehicle transport services",
      ];
      break;
    case "TrucksAndTrailers":
      keywords = [
        "heavy vehicle transport",
        "truck shipping North America",
        "trailer transport services",
        "reliable heavy vehicle shipping",
        "insured truck transport",
      ];
      break;
    case "RecreationalVehicles":
      keywords = [
        "RV transport services",
        "relocation RV shipping",
        "drive-away RV service",
        "secure RV transport",
        "North America RV transport",
      ];
      break;
    case "Watercraft":
      keywords = [
        "boat transport services",
        "yacht shipping North America",
        "secure watercraft transport",
        "insured boat transport",
        "professional yacht shipping",
      ];
      break;
    case "CargoTransport":
      keywords = [
        "commercial cargo shipping",
        "insured cargo transport",
        "heavy cargo shipping North America",
        "reliable cargo transport services",
        "custom cargo shipping solutions",
      ];
      break;
    case "GeneralAndLocationBased":
      keywords = [
        "North America transport services",
        "insured shipping solutions",
        "Easemover transport options",
        "cross-country transport services",
        "specialized transport providers North America",
      ];
      break;
    default:
      keywords = [
        "easemover",
        "ease mover",
        "EaseMover",
        "Ease Mover"
      ];
  }

  return (
    <Helmet>
      <title rel="canonical">{title}</title>
      <meta name="og:title" content={title} rel="canonical" />
      <meta name="title" content={title} rel="canonical" />
      <meta name="description" content={description} rel="canonical" />
      <meta name="og:description" content={description} rel="canonical" />
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}
      <meta name="og:image" content={image} />
    </Helmet>
  );
};

export default MetaTags;
