import React, { useEffect } from "react";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import Modal from "../userside/confirmModal";
import Img from "../../assets/close.jpg";
import { useParams } from "react-router-dom";
const PaymentForm = ({ setShow, quote, getData, status }) => {
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState({
    show: false,
    title: "",
  });
  const [paymentComplete, setPaymentComplete] = useState({
    paymentMethod: false,
    quote: "",
  });
  const handleModal = () => {
    setShowModal((st) => ({
      ...st,
      show: true,
      title: " Shipment Confirmed !",
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Send the payment method and card number to your server to save it
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}shipment/payments`,
          {
            amount: Number(quote)?.toFixed(0),
            currency: "usd",
            source: {
              object: "card",
              number: paymentMethod.card.last4,
              exp_month: paymentMethod.card.exp_month,
              exp_year: paymentMethod.card.exp_year,
              cvc: paymentMethod.card.cvc,
            },
          }
        );
        if (response.status === 200) {
          setPaymentComplete((st) => ({
            ...st,
            paymentMethod: true,
          }));
          sendPaymentConfirmation();
          handleModal();
        } else {
          console.error("Failed to save payment method:", response.statusText);
        }
      } catch (error) {
        console.error("Failed to save payment method:", error);
      }
    }
  };
  const sendPaymentConfirmation = () => {
    const payload = {
      paymentMethod: paymentComplete?.paymentMethod,
      quote: Number(quote).toFixed(0),
      paymentMode: status?.paymentMode,
      confirmationStatus: status?.confirmationStatus,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_KEY}shipment/edit/${params?.id}`,
        payload
      )
      .catch((err) => {
        console.log("errr", err);
      });
  };

  return (
    <div class=" bg-gray-200 flex items-center justify-center px-5 pb-10 mt-16">
      <div class="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700 max-w-[600px]">
        <div class="w-full pt-1 pb-5">
          <div class="bg-indigo-500 text-white overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center">
            <i class="mdi mdi-credit-card-outline text-3xl"></i>
          </div>
          <div className="float-right">
            <img
              className="h-[16px] cursor-pointer"
              src={Img}
              alt="usman"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        <div class="mb-10">
          <h1 class="text-center font-bold text-xl uppercase">
            Secure payment info
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="mb-3 flex -mx-2">
            <div class="px-2">
              <label for="type1" class="flex items-center cursor-pointer">
                <input
                  type="radio"
                  class="form-radio h-5 w-5 text-indigo-500"
                  name="type"
                  id="type1"
                  checked
                />
                <img
                  src="https://leadershipmemphis.org/wp-content/uploads/2020/08/780370.png"
                  class="h-[40px] ml-3"
                />
              </label>
            </div>
          </div>
          <div class="mb-3">
            <label class="font-bold text-sm mb-2 ml-1">Amount</label>

            <div>
              <p className="font-bold border-1">${Number(quote)?.toFixed(0)}</p>
            </div>
          </div>
          <div class="mb-3">
            <label class="font-bold text-sm mb-2 ml-1">Card number</label>
            <label class="font-bold text-sm mb-2 float-right mr-[75px]">
              Expiry
            </label>
            <div style={{ color: "red" }}>{errorMessage}</div>
            <div>
              <CardElement
                options={{
                  iconStyle: "solid",
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                  hidePostalCode: true,
                }}
              />
            </div>
          </div>

          <div>
            <button class="block mt-10 w-full max-w-xs mx-auto bg-blackish hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
              <i class="mdi mdi-lock-outline mr-1"></i> PAY NOW
            </button>
          </div>
        </form>
      </div>
      {showModal?.show && (
        <Modal setShowModal={setShowModal} showModal={showModal} />
      )}
    </div>
  );
};

export default PaymentForm;
