/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Img1 from "../../../assets/8.png";
export default () => {
  const [details, setDetails] = useState([]);
  const Navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}shipment/tr/${params.id}`);
        if (response.ok) {
          const data = await response.json();
          setDetails(data);
          // console.log(data); // Show data in console
        } else {
          console.error('HTTP error', response.status);
        }
      } catch (error) {
        console.error('Fetch error', error);
      }
    };

    fetchData();
  }, [params.id]);

  return (
    <>
      <div className="vehicle-info bg-buildings bg-cover bg-no-repeat ">
        <div className='flex justify-center py-6 '>
          <div className="header container flex gap-6 shadow-2xl rounded-2xl">
            <img className='h-[60px]' src={Img1} alt="icon" />
            <h1 className='text-icon text-5xl font-bold'>Easemover</h1>
          </div>
        </div>
        <div className="flex justify-center  ">
          <div className="container  bg-white max-w-[1300px]" >
            <div className="benchmark">
              <div className="vehicle-detail  border-2 border-white pt-5 pb-5 px-2 mt-6">
                <div className="main-heading text-center">
                  <h1 className="text-2xl font-bold">Shipment Detail</h1>
                </div>
                <div className="flex justify-between px-4">
                  <div className="tracking-id mt-8">
                    <h3 className="font-semibold">Tracking# <span className="font-normal">{details.trackingId}</span> </h3>
                    <p className=" font-normal mb-4">{details.firstName} {details.lastName}</p>
                    <div className="flex">
                      <div>
                        <img src="/pinx.svg" alt="pin" className="mb-1" />
                        <img src="/Line.svg" alt="pin" className="mb-1 ml-1.5" />
                        <img src="/pinx.svg" alt="pin" className="mb-1" />
                      </div>
                      <div className="ml-2 flex flex-col justify-between">
                        <p className="-mt-1">{details.origin}</p>
                        <p>{details.destination}</p>
                      </div>
                    </div>
                  </div>
                  <div className="info mt-8 text-sm">
                    <p>For shipment related inquires call - <span className="font-semibold">210-394-3433</span> <br></br>or email at booking@easemover.com</p>
                  </div>
                </div>

                <div className="container  bg-white max-w-[1300px] mt-16">
                
                    <h1 className="font-semibold text-xl">Shipment Status</h1>
                    <div className="mt-8">

                     




                      <div className="flex flex-col mt-24">
                        {details?.history?.map((hs, index) => (
                          <div className="flex ">

                            <div>
                              {/* <img src="/pinx.svg" alt="pin" className="mb-1" /> */}
                              <div className="h-[22px] w-[22px] bg-icon rounded-full mb-2 "></div>
                              <img src="/Line.svg" alt="pin" className="mb-2 ml-[10px]" />
                              {/* <img src="/pinx.svg" alt="pin" className="mb-1"/> */}
                            </div>

                          <p className="text-base -mt-1.5 ml-10 capitalize">{hs}</p>
                          </div>
                        ))}

                        


                  
                    </div></div>
                  {/* <div className="">
                      <h1 className="font-semibold text-xl">Customer Details</h1>
                      <div className="mt-8">
                       <p>{details.firstName} </p>
                       <p>{details.phone}</p>
                       <p>{details.email}</p>
                      </div>
                      <div className="mt-16">
                       <p className="font-semibold">Pickup Address </p>
                       <p className="w-[ 250px]">{details.pickupAddress}</p>
                       
                      </div>
                      <div className="mt-16">
                       <p className="font-semibold">Delivery Address </p>
                       <p className="w-[250px]">{details.shipAddress}</p>
                      
                      </div>
                    </div> */}

                </div>
                <div className="type-selection  mt-8">
                  <div className="origin p-3 flex flex-col justify-center">

                    <div className="flex justify-center">

                      <button className="text-blackish text-white mt-5 font-semibold py-3 rounded px-16 bg-icon" onClick={() => Navigate("/")}>
                        Go to HomePage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
