export const metas = {
  title: "Ease Mover",
  description:
    "Welcome to Ease Mover, your trusted partner for hassle-free shipment services. We are a reliable and efficient company committed to providing seamless shipping solutions to our valued clients.",
};


export const services = {
  title: "Services",
  description:
    "At Easemover, we specialize in providing tailored transport solutions across North America. We understand the importance of safe and secure delivery, which is why all our shipments are fully insured and covered for maximum protection. Whether you need to ship a classic car, a family RV, or heavy cargo, our diverse service options are designed to meet your needs efficiently and securely. Explore our range of vehicle Shipping services to find the perfect fit for your transportation requirements.",
};


export const hiw = {
  title: "How It Work",
  description:
    "Simple steps to get your vehicle where it needs to be.",
};

export const con = {
  title: "Contact Us",
  description:
    "Get in touch",
};
