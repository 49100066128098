import Header from "../components/header";
import tictok from "../assets/tik.svg";
import instagram from "../assets/ship.svg";
import linked from "../assets/lin.svg";
import { useNavigate } from "react-router-dom";
import MetaTags from "../components/userside/metaTags";

import { con } from "../components/userside/constent";

const ListItem = ({ title, para, para2, icon }) => {
  return (
    <>
     <MetaTags
        title={con.title}
        description={con.description}
        image={`${window.location.origin}/favicon.ico`}
      />
    <div className=" mb-12">
      <h1 className="font-bold text-[22px] md:text-[36px]">{title}</h1>
      {icon ? (
        <div className="mt-8 flex gap-4">
           <div>
          <a href="https://www.instagram.com/easemover?utm_source=qr&igsh=MTFrMGdpeGpndmJoMQ%3D%3D" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={instagram} alt="" />
            </a>
          </div>
          <div>
          <a href="https://www.tiktok.com/@easemover?_t=8ljmKdkRiLN&_r=1" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={tictok} alt="" />
            </a>
          </div>
          <div>
          <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <img className=" h-[36px] w-[36px]" src={linked} alt="" />
            </a>
          </div>
        </div>
      ) : (
        <>
          <p className="mt-2 text-base md:text-2xl md:font-bol font-medium ">{para}</p>
          <p className="text-base md:text-2xl md:font-bold font-medium ">{para2}</p>
        </>
      )}
    </div>
    </>
  );
};

const ContactUs = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className=" bg-[#0D1926]">
        <div className="container max-w-7xl mx-auto">
          <div className=" min-h-20 py-10">
            <Header showLogo={false} />
            <div>
              <h1 className=" mt-6  text-icon text-4xl font-extrabold ]">
                Contact
                <span className=" text-white"> us</span>
              </h1>
              <p className="mt-4 text-white text-xl">Get in touch</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-7xl mx-auto py-20">
        
        <ListItem title={"Canada"} para=" +1 (647) 781-2408" />
        <ListItem title={"USA"} para="+1 (813) 492-4283" />
        <ListItem title={"Email"} para="Booking@easemover.com" />
        <ListItem title={"Social Media"} icon={true} />
        <div className="mb-10 flex justify-center gap-10">
        {/* <button onClick={() => navigate('/#quote')} className="text-white bg-[#2B2A2A] rounded-[20px] p-3 px-10">
            Get a qoute
          </button> */}
          <button onClick={() => navigate('/#callback')} className="text-white bg-[#2B2A2A] rounded-[20px] p-3 px-10">
            Request a callback
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
