/* eslint-disable import/no-anonymous-default-export */
import { useNavigate } from "react-router-dom";
export default ()=>{
    const Navigate=useNavigate();
    return(
        <>
        <div className="track-order bg-blackish flex justify-center p-0 md:p-20">
            <div className="container pb-10 pt-10 md:pb-0 md:pt-0">
                <div className="align-mid grid md:grid-cols-2 grid-cols-1 gap-4 items-center">
                    <div className="main-head flex justify-center ">
                        <h1 className="text-white text-2xl md:text-5xl leading-normal font-semibold pr:0 md:pr-16">Track Your Order</h1>
                    </div>
                    <div className=" text md:px-10 px-0  col-span-1">
                        <h1 className="text-white pl:0 md:pl-16 mt-1 text-sm md:text-base text-center md:text-start ">To track your order click the button below</h1>
                        <hr className="text-white md:w-16 w-full md:ml-16 ml-0 mt-5"></hr>
                       <div className="flex justify-center md:justify-start">
                       <button className="text-blackish bg-icon md:bg-transparent font-bold md:font-normal  mt-4 cursor-pointer hover:font-semibold md:ml-16 ml-0 p-4 md:bg-transparent md:p-2" 
                        onClick={()=>Navigate('/Trackshipment')}>Track Now</button>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}