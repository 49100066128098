import { useNavigate } from "react-router-dom";
import Header from "../components/header";

const ListItem = ({ title, para, para2, icon }) => {
  return (
    <div className=" mb-12">
      <h1 className="font-bold text-[12px] md:text-[12px] lg:text-base">{title}</h1>
      {icon ? (
        <div></div>
      ) : (
        <>
          <p className="mt-1 text-[12px] md:text-[12px] lg:text-base  pl-[28px]">{para}</p>
          <p className="text-[12px] pl-[28px]">{para2}</p>
        </>
      )}
    </div>
  );
};

const Faqs = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className=" bg-[#0D1926]">
        <div className="container max-w-7xl mx-auto">
          <div className=" min-h-20 py-10">
            <Header showLogo={false} />
            <div>
              <h1 className=" mt-6 text-white text-4xl font-extrabold ]">
                <span className=" text-icon"> FAQ</span>
              </h1>
              <p className="mt-4 text-white text-xl">
                All you need to know to get started
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-7xl mx-auto py-20">
        <p className="mb-8 font-bold underline text-[15px] md:text-base lg:text-xl">
          General Questions
        </p>
        <ListItem
          title={"1. What transport services do you offer?"}
          para="We provide nationwide vehicle and cargo transportation, focusing on quality customer care and affordability."
        />
        <ListItem
          title={"2. Are you licensed and insured?"}
          para="Yes, Easemover is a fully licensed and insured transport business, ensuring reliability and safety in all our       operations."
        />
         <p className="mb-8 font-bold underline text-[15px] md:text-base lg:text-xl">
          Booking and Scheduling
        </p>

        <ListItem
          title={"1. How do I get a transport quote?"}
          para="You can easily get an instant quote by filling out the form on our website with your shipping details and vehicle information."
        />
        <ListItem
          title={"2. How far in advance do I need to book my transport?"}
          para="While we can accommodate various scheduling needs, booking in advance ensures the best availability to suit your shipping timeline."
        />
        <p className="mb-8 font-bold underline text-[15px] md:text-base lg:text-xl">
          Pricing and Payments
        </p>

        <ListItem
          title={"1. How are the transport costs calculated?"}
          para="Costs are calculated based on the type and size of the vehicle, transport type, distance, and specific requirements like operability."
        />
        <ListItem
          title={"2. What payment methods are accepted?"}
          para="We accept multiple payment options, including major credit cards and bank transfers."
        />

<p className="mb-8 font-bold underline text-[15px] md:text-base lg:text-xl">
          Insurance and Safety
        </p>

        <ListItem
          title={"1. What type of insurance coverage do you provide?"}
          para="Our services include full insurance coverage for maximum protection during transport."
        />
        <p className="mb-8 font-bold underline text-[15px]">
          Vehicle Preparation
        </p>

        <ListItem
          title={"1. How should I prepare my vehicle for transport?"}
          para="Ensure your vehicle is clean, remove personal items, and have only a quarter tank of gas. Make sure your vehicle is operable unless specified otherwise."
        />
      <p className="mb-8 font-bold underline text-[15px] md:text-base lg:text-xl">
          Tracking and Receiving
        </p>
        <ListItem
          title={"1. How can I track my shipment?"}
          para="You can track your order in real-time through our website by clicking the 'Track Now' button and entering your details."
        />
        <ListItem
          title={"2. What should I expect when receiving my vehicle?"}
          para="Expect timely updates during transport, and thorough communication until your vehicle is safely delivered."
        />
          <p className="mb-8 font-bold underline text-[15px]">
          Customer Feedback
        </p>
        <ListItem
          title={"1. What do customers say about your services?"}
          para="Our customers praise the ease and simplicity of the shipping process, excellent customer service, and competitive rates. Check out testimonials from clients like Claire Brooks from Michigan and Alexa Young from California on our site."
        />

<div className=" mt-10 flex gap-2 md:gap-10 ">
          <button onClick={() => navigate('/#quote')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Get a quote
          </button>
          <button onClick={() => navigate('/#callback')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Request a callback
          </button>
        </div>
      </div>
    </>
  );
};

export default Faqs;
