import Header from "../components/header";
import Service1 from "../assets/service1.png";
import Service2 from "../assets/service2.png";
import Service3 from "../assets/service3.png";
import Service4 from "../assets/service4.png";
import Service5 from "../assets/service5.png";
import Service6 from "../assets/service6.png";
import Service7 from "../assets/ins.svg";
import { useNavigate } from "react-router-dom";
import MetaTags from "../components/userside/metaTags";

import { services } from "../components/userside/constent";


const ListItem = ({ title, para, para2, icon,css }) => {
  return (
    <div className=" mb-12">
      <h1 className={`font-bold text-[20px] lg:text-2xl  ${css}`}>{title}</h1>
      {icon ? (
        <div></div>
      ) : (
        <>
          <p className="mt-4 text-[12px] md:text-[12px] lg:max-w-4xl lg:text-base">{para}</p>
          <p className="text-base font-medium">{para2}</p>
        </>
      )}
    </div>
  );
};

const ListItemWithIcon = ({ title, para, para2, icon, listData,css }) => {
  return (
    <div className=" mb-12 ">
      <div className="flex justify-between items-center">
        <h1 className={`font-bold text-[14px] md:text-[14.5px] lg:text-xl ${css}`}>{title}</h1>
        <div>
          <img className="h-[50px] w-[60px] lg:w-[80px] lg:h-[70px]" src={icon} alt="" />
        </div>
      </div>
      <div>
        <ul className=" list-disc pl-8">
          {listData?.map((single, index) => (
            <li key={index} className="text-[12px] md:text-[12px] lg:max-w-4xl lg:text-base ">
              {single?.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Service = () => {
  const navigate = useNavigate()
  return (
    <>
     <MetaTags
        title={services.title}
        description={services.description}
        image={`${window.location.origin}/favicon.ico`}
      />
  
      <div className=" bg-[#0D1926]">
        <div className="container max-w-7xl mx-auto">
          <div className=" min-h-20 py-10">
            <Header showLogo={false} />
            <div>
              <h1 className=" mt-6 text-white text-4xl font-extrabold ">
                <span className=" text-icon"> Services</span>
              </h1>
              <p className="mt-4 text-white text-xl">Offered By Us</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-7xl mx-auto py-20">
        <ListItem
          title={"Our Transport Services"}
          css={"text-gradiant"}
          para="
          At Easemover, we specialize in providing tailored transport solutions across North America. We understand the importance of safe and secure delivery, which is why all our shipments are fully insured and covered for maximum protection. Whether you need to ship a classic car, a family RV, or heavy cargo, our diverse service options are designed to meet your needs efficiently and securely. Explore our range of vehicle Shipping services to find the perfect fit for your transportation requirements."
        />
        <h1  className="font-bold text-[20px] lg:text-2xl text-start md:text-center mb-12 text-gradiant">
    Vehicle Shipping
</h1>

        <ListItemWithIcon
          title={"Classic Cars"}
          icon={Service1}
          listData={[
            {
              value:
                "Specialized transportation for vintage and classic cars, ensuring your prized possession is handled with the utmost care.",
            },
            {
              value:
                "Options: Choose between open or enclosed transport to match your security and budget needs.",
            },
            {
              value:
                "Benefits: Safe, secure, and timely  delivery, with full insurance coverage to protect your vehicle’s condition.",
            },
          ]}
        />
        <ListItemWithIcon
          title={"Trucks and Trailers"}
          icon={Service2}
          listData={[
            {
              value:
                "Capable of transporting heavy vehicles including large trucks and trailers across any distance within North America.",
            },
            {
              value:
                "Options: Open transport for cost efficiency or enclosed transport for maximum protection, each fully      insured.",
            },
            {
              value:
                "Benefits: Fast, reliable service with real-time tracking and full insurance for peace of mind.",
            },
          ]}
        />
        <ListItemWithIcon
          title={"Recreational Vehicles (RVs)"}
          icon={Service3}
          listData={[
            {
              value:
                "Tailored solutions for moving RVs, perfect for owners who are relocating or dealerships managing inventory.",
            },
            {
              value:
                "Options: Drive-away service to ensure your RV is ready to use upon arrival, with comprehensive insurance.",
            },
            {
              value:
                "Benefits: Experienced drivers and top-tier customer service to handle your RV with the utmost care, fully insured for transport.",
            },
          ]}
        />
        <ListItemWithIcon
          title={"Watercraft"}
          icon={Service7}
          listData={[
            {
              value:
                "Expert transportation services for all types of watercraft, from speedboats to yachts, ensuring safe passage over land to your destination. Each vessel is treated with specialized care and attention to detail.",
            },
            {
              value:
                "Options:  Choose from a variety of transport options including trailer, cradle, or on your own custom trailer. Opt for additional tarp coverage for open transport to shield from the elements, or select enclosed transport for the highest level of protection.",
            },
            {
              value:
                "Benefits: Each transport comes with assured safety, punctual delivery, and comprehensive insurance to safeguard your boat’s pristine condition",
            },
          ]}
        />
        <h1 className="font-bold text-[20px] lg:text-2xl text-start md:text-center mb-12 text-gradiant">
          Cargo Transport
        </h1>
        <ListItemWithIcon
          title={"Individual and Dealership Cargo"}
          icon={Service4}
          listData={[
            {
              value:
                "Comprehensive transport solutions for personal and commercial cargo adaptable to any size and weight",
            },
            {
              value:
                "Options: Customized to the cargo’s specific requirements, with robust handling procedures and full insurance",
            },
            {
              value:
                "Benefits: Efficient, secure transport ensuring your cargo arrives on time and intact, with insurance coverage for all shipments.",
            },
          ]}
        />
        <h1 className="font-bold text-[20px]  lg:text-2xl text-start md:text-center mb-12 text-gradiant">
        Shipping Options
        </h1>
        <ListItemWithIcon
          title={"Open, Enclosed, and Drive-Away"}
          icon={Service5}
          listData={[
            {
              value:
                "Flexibility to choose the transport type that best suits your needs.",
            },
            {
              value:
                "Open Transport: Economical choice, suitable for most vehicles and cargo, with full insurance coverage.",
            },
            {
              value:
                "Enclosed Transport: Offers enhanced protection from the elements and added security for high-value shipments, all insured.",
            },
            {
              value:
                "Drive-Away Service: Ideal for ready-to-drive vehicles needing direct delivery, fully covered by insurance.",
            },
          ]}
        />

        <ListItemWithIcon
          title={"Why choose easemover?"}
          icon={Service6}
          css={" text-gradiant"}
          listData={[
            {
              value:
                "Trusted Service: Fully licensed and insured,  with a commitment to quality and customer care.",
            },
            {
              value:
                "Nationwide Coverage: Ability to ship anywhere in North America, accommodating a range of sizes and weights.",
            },
            {
              value:
                "Custom Solutions: Each shipment is treated uniquely, with services tailored to the specific needs of our clients and full insurance protection.",
            },
          ]}
        />
        <div className=" mt-10 flex gap-2 md:gap-10 ">
          <button onClick={() => navigate('/#quote')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Get a quote
          </button>
          <button onClick={() => navigate('/#callback')} className="text-white bg-[#2B2A2A] text-sm rounded-[20px] py-4 px-4 md:px-6 lg:px-10">
            Request a callback
          </button>
        </div>
      </div>
    </>
  );
};

export default Service;
