
export default({ setShowModal, showModal })=>{
    return(
        <>
        
          <div class="fixed flex justify-center items-center bg-[#000000d4] top-0 left-0 right-0 z-50   p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
      <div class="w-[30rem] modal-dialog relative pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col  pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header text-center  p-4 0 rounded-t-md">
            <h5
              class="text-2xl leading-normal text-icon "
              id="exampleModalLabel"
            >
              {showModal?.title}
            </h5>
            <i class="fa-solid fa-circle-check text-3xl text-icon mt-3"></i>
            <button
              type="button"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4  rounded-b-md">
            <button
              type="button"
              class="px-6
          py-2.5
          bg-purple-600
           font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
          hover:bg-purple-700 hover:shadow-lg
          focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
          active:bg-purple-800 active:shadow-lg
          transition
          duration-150
          ease-in-out text-sm"
              onClick={() => {
                setShowModal(false)
              }}
            >
              Close 
              <i class="fa-regular fa-circle-xmark ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div> 
        </>
    )
}