/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import React, { Component, useState } from "react";
import Autocomplete from "react-autocomplete";


export default ({
  state,
  setState,
  origin,
  handleChange,
  value,
  handleSelect,
  className
}) => {

  return (
    <div className="location w-full  relative">
      <Autocomplete
        getItemValue={(item) => item?.address?.formattedAddress}
        items={state?.data || []}
        menuStyle={{
          top: 55,
          left: 0,
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'absolute',
          overflow: 'auto',
        }}
        renderItem={(item, isHighlighted) => (
          <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
            {item?.address?.formattedAddress}
            <br />
            {item?.address?.countryRegion}
          </div>
        )}
        value={value || ""}
        onChange={handleChange}
        onSelect={handleSelect}
        className={className}
      />
    </div>
  );
};
