import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";
import PaymentForm from "./paymentForm";
export default ({ setShow, quote, getData, status }) => {
  const PUBLIC_KEY = "pk_test_NXCbXQ5pCNbqcUwTQYsYlnV000SV6eiSWz";
  const stripeTestPromise = loadStripe(PUBLIC_KEY);
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm
        setShow={setShow}
        quote={quote}
        getData={getData}
        status={status}
      />
    </Elements>
  );
};
