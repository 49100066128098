/* eslint-disable import/no-anonymous-default-export */
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
export default () => {
  const startDate = moment().format("DD-MM-YYYY");
  const endDate = moment().add(4, "day").format("DD-MM-YYYY");
  const [getRate, setGetRate] = useState(null);
  const [allSalesTax, setAllSalesTax] = useState(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const data = location.state;

  const sendData = () => {
    const payload = {
      vehicle: data?.vehicle || [],
      firstName: data?.firstName || "",
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      quote: quote,
      priority: data?.priority,
      origin: data?.origin,
      destination: data?.destination,
      transportType: data?.transportType,
      shipAddress: data?.shipAddress,
      pickupAddress: data?.pickupAddress,
      pickupDate: data?.pickupDate,
      customerNote: data?.customerNote,
      enclosedRate: getRate?.enclosed?.enclosedPrice,
      shipAsapFee: getRate?.shipAsap?.shipPrice,
      finalDistance: Number(data?.finalDistance * 0.6)?.toFixed(2),
      companyId: "64193e21746725002d3fdad2",
      salesTax: allSalesTax?.taxRate,
      calculatedDistance: Number(data?.finalDistance * 0.6)?.toFixed(2),
    };
    setLoader(true);
    axios
      .post(`${process.env.REACT_APP_API_KEY}shipment/add/`, payload)
      .then((res) => {
        navigate(`/finalinvoice/${res.data.savedEvent._id}`, {
          state: {
            getRate: getRate,
            data: payload,
            total: quote,
            saleTax: allSalesTax,
          },
        });
      });
  };
  const getRatePerMile = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}rate/64193e21746725002d3fdad2`)
      .then((res) => {
        setGetRate(res.data);
        setLoader(false);
      });
  };

  const getAllSalesTax = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}rate/AllsaleTax/64193e21746725002d3fdad2`
      )
      .then((res) => {
        const filter = res?.data?.find((x) =>
          data?.destination.includes(x.taxState || x.taxStateAbbr)
        );
        setAllSalesTax(filter);
      })
      .catch(() => {
        setAllSalesTax(null);
      });
  };

  useEffect(() => {
    getRatePerMile();
    getAllSalesTax();
  }, []);

  function quotek() {
    let total = 0;
    total =
      Number(data?.finalDistance * 0.6) * Number(getRate?.rate?.ratepermile);
    if (data.transportType == "enclosed") {
      total = total + Number(getRate?.enclosed?.enclosedPrice);
    }
    if (data.priority === "Ship ASAP") {
      total = total + Number(getRate?.shipAsap?.shipPrice);
    }
    if (allSalesTax != null) {
      total = total + Number(allSalesTax?.taxRate);
    }

    return total?.toFixed(2);
  }
  const quote = quotek();

  return (
    <>
      <div className="flex justify-center">
        <div class=" z-10 overflow-y-auto top-0 w-full ">
          <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-blackish opacity-75" />
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div class="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all mt-16">
              <div class="bg-white px-32 ">
                <div className="bookings flex justify-center mt-8 pb-5">
                  <div className="container flex justify-center">
                    <div className="main-heading text-center">
                      <h5 className="font-bold mt-8 text-3xl ">
                        <span className="text-3xl text-icon">Ease Mover</span>
                        <br></br>Your Custom Quotes
                      </h5>

                      <div className="booking-plan  flex justify-center mt-8">
                        <div className="list max-w-lg border-2 rounded border-black  px-20 md:px-10 py-8">
                          <ul>
                            {/* <li className="py-5 font-semibold">RECOMMENDED</li> */}
                            {getRate === null ? (
                              <div className="text-center w-full px-3 text-black p-3 font-semibold text-lg bg-dark-gray">
                                <svg
                                  class="animate-spin inline text-black h-20 w-20 border-4  ..."
                                  viewBox="0 0 0 0"
                                ></svg>
                              </div>
                            ) : (
                              <div>
                                <li className="py-5 font-extrabold text-3xl border-black border-2">
                                  ${quote}
                                  <p className="text-sm  font-medium">
                                    Tax Inc
                                  </p>
                                </li>
                              </div>
                            )}

                            {data.priority === "Ship ASAP" ? (
                              <div>
                                <li className="py-5 text-black font-medium ">
                                  {data.priority} <br></br>{" "}
                                  <span className="text-xs">
                                    {startDate} to {endDate}
                                  </span>
                                </li>
                              </div>
                            ) : (
                              <div>
                                <li className="py-5 text-black font-medium">
                                  {data.priority} From <br></br>
                                  <span className="text-xs">
                                    {data?.pickupDate}
                                  </span>
                                </li>
                              </div>
                            )}

                            <div>
                              <li className="py-5 text-black font-medium">
                                Transport Type <br></br>
                                <span className="text-xs">
                                  {data?.transportType}
                                </span>
                              </li>
                            </div>

                            <div className="text-start mt-4">
                              <h3 className="text-start font-semibold text-xl">
                                Shipment Includes
                              </h3>
                              <li className="pt-2 text-black font-normal">
                                Rate Per Mile:{" "}
                                {getRate?.rate?.ratepermile || ""}{" "}
                                {getRate?.rate?.ratepermile < 1 ? "cent" : "$"}
                              </li>
                              <li className="pt-2 text-black font-normal">
                                Distance:{" "}
                                {(data?.finalDistance * 0.6)?.toFixed(2) || ""}{" "}
                                Miles
                              </li>
                              {data.transportType == "enclosed" && (
                                <li className="pt-2 text-black font-normal">
                                  Enclosed Fee: $
                                  {getRate?.enclosed?.enclosedPrice || ""}
                                </li>
                              )}
                              {data.priority === "Ship ASAP" && (
                                <li className="pt-2 text-black font-normal">
                                  Ship ASAP Fee: ${getRate?.shipAsap?.shipPrice}
                                </li>
                              )}
                              <li className="pt-2 text-black font-normal">
                                Instant notification
                              </li>
                              <li className="pt-2 text-black font-normal">
                                Licensed and insured driver
                              </li>
                            </div>

                            {loader ? (
                              <div className="text-center w-full px-3 text-center text-black p-3 font-semibold text-lg bg-dark-gray">
                                <span className="text-black p-2 text-medium">
                                  Sending...
                                </span>
                                <svg
                                  class="animate-spin inline text-black h-20 w-20 border-4  ..."
                                  viewBox="0 0 0 0"
                                ></svg>
                              </div>
                            ) : (
                              <li className="py-3 text-blackish bg-icon font-bold text-sm md:text-base  rounded-md mt-8">
                                <button
                                  className="w-[154px] md:w-[100%]"
                                  onClick={() => {
                                    sendData();
                                  }}
                                  disabled={loader}
                                >
                                  Book My Shipment
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
