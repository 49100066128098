/* eslint-disable import/no-anonymous-default-export */
import Img from "../../../assets/8.png";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [detail, setDetatil] = useState({
    vehicle: data.repeat,
    pickupAddress: data?.origin,
    shipAddress: data?.destination

  });
  const [phone, setPhone] = useState("");

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setDetatil((st) => ({
      ...st,
      [name]: value,
    }));
  };

  const onPhoneChange = (value) => {
    setPhone(value);
  };
  let payload = {
    transportType: data?.transportType,
    pickupDate: data?.pickupDate,
    priority: data?.priority,
    origin: data?.origin,
    destination: data?.destination,
    finalDistance: data?.finalDistance,
    ...detail,
    phone,
  };
  useEffect(() => {
    const handleTabClose = (event) => {
      // Send API request to server here
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/shipment/addAbandonCart`,
          {
            detail,
          }
        )
        .then((response) => {
          console.log("API request successful:", response.data);
        })
        .catch((error) => {
          console.error("API request failed:", error);
        });
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [detail]);
  const onSubmit = () => {
    console.log({ state: payload });
    if (detail?.firstName && detail?.lastName && phone && detail?.email) {
      navigate("/bookingplans/", { state: payload });
    } else {
      alert("Please Fill All Fields");
    }
  };
  return (
    <>
      <div className="main bg-blackish">
        <div className="flex justify-center border-2  py-6 border-b-icon">
          <div className="header container flex gap-6">
            <img className="h-[60px]" src={Img} alt="icon" />
            <h1 className="text-icon text-5xl font-bold">Easemover</h1>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="container max-w-[1080px]">
            <div className="head text-center text-white mt-12">
              <h1 className="text-3xl font-semibold text-icon">
                Almost There !
              </h1>
            </div>
            <div className="flex justify-evenly gap-6 mt-16">
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  First Name*
                </label>
                <input
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                  id="grid-first-name"
                  type="text"
                  placeholder="Enter Your First Name"
                  required
                  name="firstName"
                  value={detail?.firstName}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  Last Name*
                </label>
                <input
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
                  id="grid-first-name"
                  type="text"
                  placeholder="Enter Your First Name"
                  required
                  name="lastName"
                  value={detail?.lastName}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="flex justify-evenly gap-6 mt-4">
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  Email*
                </label>
                <input
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                  id="grid-first-name"
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={detail?.email}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  Phone*
                </label>
                <PhoneInput
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                  placeholder="Enter Phone Number"
                  name="phone"
                  value={phone}
                  onChange={onPhoneChange}
                />
                {/* <input className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none " id="grid-first-name" type="number" placeholder="Enter Phone Number" required
                                    name="phone"
                                    value={detail?.phone}
                                    onChange={(e) => onInputChange(e)}
                                /> */}
              </div>
            </div>
            <div className="flex justify-evenly gap-6 mt-4 ">
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  Detailed Pickup Address*
                </label>
                <input
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
                  id="grid-first-name"
                  type="text"
                  placeholder="Enter Pickup Address"
                  required
                  name="pickupAddress"
                  value={detail?.pickupAddress}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div className="w-60">
                <label
                  className="block uppercase tracking-wide text-[#e9e8e9] text-xs font-semibold mb-2"
                  for="grid-first-name"
                >
                  Detailed Dropoff Address*
                </label>
                <input
                  className="appearance-none block w-100 mt-4 bg-gray-200 text-sm md:text-base text-white bg-blackish border border-[#4a535c] rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
                  id="grid-first-name"
                  type="text"
                  placeholder="Detailed Dropoff Address"
                  required
                  name="shipAddress"
                  value={detail?.shipAddress}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="flex mt-4 pb-16">
              <div className="w-60">
                <label
                  for="message"
                  className="block uppercase tracking-wide mt-3  text-[#e9e8e9] text-xs font-semibold mb-2"
                >
                  Customer Note
                </label>
                <textarea
                  id="message"
                  rows="4"
                  className="bappearance-none block text-sm md:text-base mt-3 w-100 bg-blackish border border-[#4a535c] text-white rounded py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                  placeholder="Enter Your Message"
                  required
                  name="customerNote"
                  value={detail?.customerNote}
                  onChange={(e) => onInputChange(e)}
                ></textarea>
              </div>
            </div>

            <div className="flex justify-center gap-8 pb-20 flex-wrap">
              <div className="">
                <button
                  className="py-2 px-20 bg-blackish text-icon border border-icon"
                  onClick={() => navigate("/")}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  className="py-2 px-16 bg-icon"
                  onClick={(e) => {
                    onSubmit(e);
                  }}
                >
                  View Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
