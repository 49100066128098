/* eslint-disable import/no-anonymous-default-export */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import axios from "axios";
import Img1 from "../../../assets/mastercard.png";
import Img2 from "../../../assets/visa-credit-card.png";
import Img3 from "../../../assets/american-express.png";
import Img4 from "../../../assets/jcb.png";
import Img6 from "../../../assets/forward.png";
import Modal from "../../userside/confirmModal";
import StripeContainer from "../../payments/stripeContainer";
import { useEffect } from "react";
export default () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [allsales, setAllSales] = useState([]);
  const [showModal, setShowModal] = useState({
    show: false,
    title: "",
    taxRate: "",
  });
  const [status, setStatus] = useState({
    confirmationStatus: "confirmedBooking",
  });
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setStatus((st) => ({
      ...st,
      [name]: value,
    }));
  };
  const handleModal = () => {
    setShowModal((st) => ({
      ...st,
      show: true,
      title: " Shipment Confirmed !",
    }));
  };
  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}rate/AllsaleTax/64193e21746725002d3fdad2`)
      .then((res) => {
        setAllSales(res.data);
      }).catch(() => {
        setAllSales([])
      })
  };

  const [quote, setQuote] = useState({
    sub: 0,
    quote: 0,
  });
  const sendPaymentConfirmation = () => {
    const payload = {
      paymentMode: status?.paymentMode,
      confirmationStatus: "confirmedBooking",
      paymentMethod: status?.paymentMethod,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_KEY}shipment/edit/${params?.id}`,
        payload
      )
      .then((res) => {
        handleModal();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log(location);
  return (
    <>
      <div className="vehicle-info ">
        <div className="flex justify-center pt-0">
          <div className="container md:w-62 sm:w-100">
            <div className="movers bg-blackish text-icon py-3 pl-4 text-3xl mt-8 font-semibold flex justify-between">
              <h1>Ease Mover</h1>
              <button
                className="bg-icon px-4 py-2 border-2 text-blackish border-icon rounded text-sm mr-3"
                onClick={() => Navigate("/")}
              >
                Go Back To HomePage
              </button>
            </div>
            <div className="flex gap-2 w-1/2 border-b-gray border-2 border-white px-5 pb-10 pt-5">
              <div className="payment">
                <span>
                  <i class="fa-solid fa-greater-than text-[14px] font-bold"></i>
                  &nbsp; Information
                </span>
              </div>
              <div className="payment">
                <span>
                  <i class="fa-solid fa-greater-than text-[14px] font-bold"></i>{" "}
                  &nbsp;Shipping
                </span>
              </div>
              <div className="payment">
                <span>
                  {" "}
                  <i class="fa-solid fa-greater-than text-[14px] font-bold"></i>
                  &nbsp; Payment
                </span>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              <div className="intant-quote order-2 md:order-1 ">
                <div className="   -10 mt-4  ">
                  <h1 className="text-2xl font-bold">Payment Details</h1>
                  <div className="rounded-2xl border border-gray p-6 mt-3">
                    <div className=" mt-3">
                      <h1 className="mt-3 font-semibold">
                        Proceed with Payment
                      </h1>
                      <p className="font-medium text-sm cursor-pointer">
                        All transactions are secure and encrypted.
                      </p>
                    </div>
                    <div className=" rounded mt-4 p-2">
                      <div className="text-sm border-b-gray border-2 border-white pb-2 flex justify-between">
                        <div className="radio-btn flex gap-2">
                          <input
                            className="cursor-pointer"
                            type="radio"
                            name="paymentMode"
                            value="card"
                            onClick={() => setShow(true)}
                            onChange={(e) => onInputChange(e)}
                          />
                          <label className="ml-3 font-semibold">
                            Credit Card
                          </label>
                        </div>
                        <div className="flex gap-2">
                          <img className="w-28" src={Img1} alt="icon" />
                          <img className="w-28" src={Img2} alt="icon" />
                          <img className="w-28" src={Img3} alt="icon" />
                          <img className="w-28" src={Img4} alt="icon" />
                          <span className="text-xs font-semibold mt-1">
                            And More...
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-center flex-col pb-2">
                        <div className="flex justify-center mt-4">
                          <img className="w-110" src={Img6} alt="icon" />
                        </div>
                        <div className="mt-4 text-center text-sm p-2 font-semibold  border-b-gray border-2 border-white">
                          <p>
                            {" "}
                            After clicking "Complete order", you will be
                            redirected to mastercard to complete your purchase
                            securely.
                          </p>
                        </div>
                        {show ? (
                          <StripeContainer
                            setShow={setShow}
                            quote={location?.state?.data?.quote}
                            getData={location?.state?.data}
                            status={status}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-sm border-b-gray border-2 border-white pb-2 ">
                        <input
                          className="cursor-pointer"
                          type="radio"
                          name="paymentMode"
                          value="cod"
                          onChange={(e) => onInputChange(e)}
                        />
                        <label className="ml-3 font-semibold">
                          Cash on Delivery
                        </label>
                      </div>
                      <div className="text-sm border-b-gray border-2 border-white pb-2 mt-6">
                        <input
                          className="cursor-pointer"
                          type="radio"
                          name="paymentMode"
                          value="cop"
                          onChange={(e) => onInputChange(e)}
                        />
                        <label className="ml-3 font-semibold">
                          Cash on Pickup
                        </label>
                      </div>
                      {status?.paymentMode?.length > 0 && (
                        <div className="flex justify-center">
                          <button
                            className="bg-blackish px-4 py-2 border-2 border-white text-white rounded text-sm mr-3 mt-4 font-semibold"
                            onClick={(e) => sendPaymentConfirmation(e)}
                          >
                            Check Out
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full order-1 md:order-2 ">
                <div className="  ">
                  <div className="detail ">
                    <h1 className="text-2xl mt-4 font-bold">Order Summary</h1>
                    {/* <div className="car-info flex justify-between">
                                            <div className="img mt-2">
                                                <img src={Img} style={{ 'height': '50px' }} alt="car" />
                                            </div>
                                            <div className="detail text-sm font-semibold pl-2 mt-4">
                                                <span className="text-xs">*Prices shown in USD</span>
                                                <div>
                                                    <p>Total Distance : <span>{((getData.finalDistance) * 0.6).toFixed(0)} Miles</span></p>
                                                </div>


                                                <p>Rate per Mile: <span>{getRate.ratepermile} cents</span></p>
                                            </div>
                                        </div> */}
                    <div className="border border-gray p-6  mt-3 rounded-2xl">
                      <div className="border-b-gray border-2 border-white pb-10 ">
                        <div className="flex justify-between text-center mt-3">
                          <h1 className="text-[20px] font-bold ">
                            Contact Information
                          </h1>
                        </div>
                        <div className="flex justify-between mt-4 text-sm">
                          <h1 className="text-[18px] text-gray">Name</h1>
                          <h2 className="text-[18px] font-semibold">
                            {location?.state?.data?.firstName}{" "}
                            {location?.state?.data?.lastName}
                          </h2>
                        </div>
                        <div className="flex justify-between mt-2 text-sm">
                          <h1 className="text-[18px] text-gray">Email</h1>
                          <h2 className="text-[18px] font-semibold">
                            {location?.state?.data?.email}
                          </h2>
                        </div>
                        <div className="flex justify-between mt-2 text-sm">
                          <h1 className="text-[18px] text-gray">Phone#</h1>
                          <h2 className="text-[18px] font-semibold">
                            {location?.state?.data?.phone}
                          </h2>
                        </div>
                      </div>
                      <>
                        <div className=" pb-6 ">
                          <div className="flex justify-between text-center mt-3">
                            <h1 className="text-[18px] text-gray">
                              Pickup From
                            </h1>
                          </div>
                          <div className="mt-4 text-sm font-semibold tracking-wide">
                            <p className="text-[18px] font-semibold">
                              {" "}
                              {location?.state?.data?.origin}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="border-b-gray border-2 border-white pb-10 ">
                          <div className="flex justify-between text-center mt-3">
                            <h1 className="text-[18px] text-gray">Ship To</h1>
                          </div>
                          <div className="mt-4 text-sm font-semibold tracking-wide">
                            <p className="text-[18px] font-semibold">
                              {" "}
                              {location?.state?.data?.destination}{" "}
                            </p>
                          </div>
                        </div>
                      </>

                      <div className="border-b-gray border-2 border-white pb-10 ">
                        <div className="flex justify-between text-center mt-3">
                          <h1 className="text-[18px] text-gray">Method</h1>
                        </div>
                        <div className="mt-4 text-sm">
                          <p className="text-[18px] font-semibold">
                            {location?.state?.data?.transportType}
                          </p>
                        </div>
                      </div>
                      <div className="border-b-gray border-2 border-white pb-10 ">
                        <div className="flex justify-between text-center mt-3">
                          <h1 className="text-[18px] text-gray">Notes</h1>
                        </div>
                        <div className="mt-4 text-sm">
                          <p className="text-[18px] font-semibold">
                            {location?.state?.data?.customerNote}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between font-semibold text-center mt-16">
                        <h1 className="text-[18px] text-gray">Subtotal</h1>

                        <h2 className="text-[18px] font-semibold cursor-pointer">
                          ${location?.state?.data?.quote}
                        </h2>
                      </div>

                      <div className="flex justify-between font-semibold text-center mt-8">
                        <div>
                          <h1 className="text-[18px] text-gray">Taxes</h1>
                        </div>

                        <div>
                          <h2 className=" font-semibold cursor-pointer text-xl">
                            {(showModal?.taxRate * 100).toFixed(2)}%
                          </h2>
                        </div>
                      </div>

                      <div className="flex justify-between font-semibold text-center mt-8">
                        <h1 className="text-[18px] text-gray">Total</h1>

                        <div>
                          <h2 className=" font-semibold cursor-pointer text-xl">
                            ${location?.state?.data?.quote}
                          </h2>
                        </div>
                      </div>
                      <div className="warranty bg-dark-gray text-sm font-semibold p-2 mt-6">
                        <p>
                          Need help ? Call us at
                          <span className="underline text-purple font-bold cursor-pointer">
                            {" "}
                            (210) 418-1575.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal?.show && (
          <Modal setShowModal={setShowModal} showModal={showModal} />
        )}
      </div>
    </>
  );
};
