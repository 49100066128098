/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import {  useState } from "react";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loader, setLoader] = useState(false);
  const [trackingId, setTrackingId] = useState("");
  const [id, setId] = useState([]);

  const loadDetail = (e) => {
    e.preventDefault();
    setLoader(true);
    if (trackingId) {
      axios
        .get(`${process.env.REACT_APP_API_KEY}shipment/tr/${trackingId}`)
        .then(
          (res) => {
            setId(res);
            navigate(`/shipmentdetail/${trackingId}`);
          },
          (reason) => {
            console.error(reason);
            setError("Invalid Tracking Id");
            setLoader(false);
          }
        );
    } else {
      alert("Please fill Tracking Id");
      setLoader(false);
    }
  };

  return (
    <>
      <div className="background  bg-buildings bg-cover bg-no-repeat h-100">
        <div className="content bg-black pb-8 pt-16">
          <div className="main-background flex justify-center ">
            <div className="container flex-col md:flex-row flex justify-evenly  pb-5">
              <div className="shipement-heading text-white font-bold text-5xl leading-normal">
                <button
                  className="rounded border-white p-2 text-white text-sm hover:border-2 hover:rounded"
                  onClick={() => navigate("/")}
                >
                  {" "}
                  Go Back
                </button>
                <h1 className="text-2xl md:4xl">
                  Track Your <br></br> Shipment
                </h1>
              </div>
              <div className="tracking-detail text-white border-b-white border border-black">
                <p className="md:mt-20 mt-10">
                  Add your tracking number below and get the updated status{" "}
                  <br></br> about your shipment
                </p>
              </div>
            </div>
          </div>
          <div className="tracker-id flex justify-center ">
            <div className="Track-order text-blackish w-52 container">
              <div className=" bg-white p-10 md:mt-20 mt-10 ">
                <h1 className="font-bold text-2xl pb-4">Track Your Order</h1>
                <span className="italic py-5">Tracking ID</span>
                <input
                  className="w-100 bg-light-white p-2"
                  placeholder="Enter Your Tracking Number"
                  type="number"
                  onChange={(e) => {
                    setTrackingId(e.target.value);
                    setError("");
                  }}
                  value={trackingId}
                />
                {error ? (
                  <span className="text-black text-xs ">{error}</span>
                ) : (
                  ""
                )}
                {loader ? (
                  <div className="text-center w-full px-3 text-center text-black p-3 font-semibold text-lg bg-dark-blue">
                    <span className="text-white p-2 text-medium">
                      Sending...
                    </span>
                    <svg
                      class="animate-spin inline text-white h-20 w-20 border-4  ..."
                      viewBox="0 0 0 0"
                    ></svg>
                  </div>
                ) : (
                  <button
                    className="w-100 bg-dark-blue text-white p-2 mt-6 italic"
                    onClick={(e) => {
                      loadDetail(e);
                    }}
                  >
                    Track
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
